<template>
  <div class="block w-full relative overflow-hidden lg:overflow-visible">
     <client-only>
      <div ref="productGallery" class="product-gallery">
        <swiper
          v-if="!$device.isDesktop"
          :options="swiperOption"
          class="flex flex-col lg:gap-y-8 swiper animate overflow-hidden"
          data-animate-type="motion-safe:animate-fadeIn">
          <swiper-slide
            v-for="(picture, index) in images"
            :key="'slide-' + index"
            class=""
            :data-slide="index">
            <nuxt-img
              class="w-full block"
              :src="picture.desktop.url.replace(/cache\/[a-z0-9]{32}\//, '')"
              :alt="picture.alt"
              provider="sirvProvider"
              fit="square"
              width="600"
              height="600" />
          </swiper-slide>
          <div class="swiper-pagination product-swiper-pagination flex flex-row gap-x-2 justify-center items-center absolute bottom-8 w-full" slot="pagination"></div>
        </swiper>
        <div v-if="$device.isDesktop">
          <div v-for="(picture, index) in images"
            :key="'image-' + index"
            :data-image="index"
            class="product-image">
            <nuxt-img
              class="w-full block"
              :src="picture.desktop.url.replace(/cache\/[a-z0-9]{32}\//, '')"
              :alt="picture.alt"
              provider="sirvProvider"
              fit="square"
              :width="imageWidth"
              :height="imageHeight" />
          </div>
        </div>
        <div class="absolute w-2.5 left-11 bottom-0 top-0 hidden lg:block" v-if="$device.isDesktop">
          <div class="gap-2 flex justify-center lg:flex-col lg:sticky lg:top-1/2 product-bullets">
            <button
              class="product-bullet rounded-full border border-lightGray p-1"
              :class="index == 0 ? 'bullet-active' : ''"
              :data-bullet="index"
              v-for="(picture, index) in images"
              :key="'bullet-' + index">
            </button>
          </div>
        </div>
      </div>
    </client-only>
  </div>
</template>
<script>
  import { defineComponent } from '@nuxtjs/composition-api';
  import { SfImage } from '@storefront-ui/vue';
  import { scrollToElement } from '~/helpers/headless/helpers';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

  export default defineComponent({
    name: "ProductGallery",
    components: {
      SfImage,
      Swiper,
      SwiperSlide,
    },
    props: {
      images: {
        type: Array,
        default: () => [],
      },
      imageWidth: {
        type: [Number, String],
        default: '952',
      },
      imageHeight: {
        type: [Number, String],
        default: '952',
      },
      current: {
        type: Number,
        default: 1,
      },
    },

    data() {
      return {
        swiperOption: {
          slidesPerView: 1,
          grabCursor: true,
          centeredSlides: true,
          speed: 700,
          autoplay: {
            delay: 4500,
            disableOnInteraction: true
          },
          pagination: {
            el: '.product-swiper-pagination'
          }
        },
        mobileState: false
      }
    },

    methods: {
      updatePagination() {
        function removeActive(who, what) {
          new Promise(resolve => {
            const activeItems = document.querySelectorAll(who)
            for (var i = 0; i < activeItems.length; i++) {
              activeItems[i].classList.remove(what)
            }
            resolve()
          })
        }

        [...document.querySelectorAll('.product-bullets button')].forEach( (bullet, index) => {
          bullet.addEventListener('click', async () => {
            // Remove other active bullets
            await removeActive(
              '.product-bullet.bullet-active',
              'bullet-active'
            )

            // Add active class
            bullet.classList.add('bullet-active')

            // Scroll to assigned slider
            let assignedSlider = document.querySelector(
              '[data-image="' + index + '"]'
            )
            scrollToElement(assignedSlider)
          })
        })

        function createInViewObserver(slide, index) {
          slide.observer = new IntersectionObserver(
            function (entries) {
              const bulletsWrapper = document.querySelector(
                '.product-bullets '
              )
              const bullet = bulletsWrapper?.querySelector(
                '[data-bullet="' + index + '"]'
              )

              if (entries[0].isIntersecting === true) {
                const active = document.querySelector(
                  '.bullet-active'
                )

                if (active) {
                  active.classList.remove('bullet-active')
                }

                bullet ? bullet.classList.add('bullet-active') : ''
              }
            },
            { threshold: [1], rootMargin: '0px 0px 80px 0px' }
          )

          slide.observer.observe(slide)
        }
        [...document.querySelectorAll('.product-image')].forEach( (slide, index) => {
          createInViewObserver(slide, index)
        })
      }
    },
    mounted() {
      if (this.$device.isDesktop) this.updatePagination()
    }
  });
</script>

<style lang="scss" scoped>
  .bullet-active {
    width: 2px;
    height: 2px;
    background-color: var(--c-primary);
    border-color: var(--c-primary)!important;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
</style>
<style lang="scss">
  .product-gallery {
    .swiper {
      &-wrapper {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
      }
      &-pagination-bullet {
        opacity: .525;
        transition: opacity cubic-bezier(0.075, 0.82, 0.165, 1) linear;
        padding: 0.25rem;
        border: 1px solid black;
        border-radius: 100%;
        &-active {
          opacity: 1;
          width: 2px;
          height: 2px;
          background-color: var(--c-primary);
          border-color: var(--c-primary) !important;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }
      &-slide {
        flex: 0 0 100%;
        width: 100%;
        height: auto;
      }
    }
  }
</style>
